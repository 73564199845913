.custom-font {
  font-size: 14px;
}

.custom-font-select {
  font-size: 12px;
}

.btn-size {
  width: 170px;
}

.row-padding {
  padding-top: 20px;
}

.btn {
  font-size: 12px;
}

.spin-center {
  position: fixed;
  top: 50%;
  left: 50%;
}
