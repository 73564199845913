.btn-feature {
  height: 100%;
  font-size: 2vw;
}

.icon-size {
  font-size: 4vw;
}

.row-padding {
  padding-top: 20px;
}
